<template>
  <div>
    <div tabindex="0" class="text-center mt-3 mt-sm-6 mb-0">
      <p class="my-0 text-h4 text-sm-h3 grey--text text--darken-2">
        Hi<span v-if="$store.state.paymentDetails.Forename !== ''"> {{ $store.state.paymentDetails.Forename }}</span>,
      </p>
      <p class="my-0 text-h6 text-sm-h5 grey--text text--darken-1">
        Let's create a standing order
      </p>
      <p class="font-italic text-sm-body-1 mr-10 mt-1 mb-0 font-weight-thin">
        It`s quick, simple and secure.
      </p>
    </div>

    <div class="pa-10 pa-sm-3 mt-sm-10 d-flex justify-center">
      <v-img v-if="this.$store.state.theme ==='agentpay'"
        :max-width="svgWidth"
        class="svg-image mt-sm-5"
        src="../assets/PayAgentBranding/undraw_standing_order.svg"
        contain
      />
      <v-img v-else
        :max-width="svgWidth"
        class="svg-image mt-sm-5"
        src="../assets/CalmonyPayBranding/undraw_standing_order.svg"
        contain
      />
    </div>

    <MainButton routeTo="StandingOrderDetails"> Get started </MainButton>

    <div tabindex="0" class="text-center mt-3">
      <p
        class="
          text-center text-sm-body-1
          font-weight-regular
          my-0
          font-weight-light
        "
      >
        {{ $store.getters.capitalizeStaffName }}
      </p>
      <p class="text-center text-sm-body-1 mt-1 mb-0 font-weight-light">
        {{ $store.state.paymentDetails.Company }}
      </p>
      <p class="text-center text-sm-body-1 mt-0 mb-3 font-weight-light">
        Tel: {{ $store.state.paymentDetails.BranchPhone }}
      </p>
    </div>

    <v-divider class="mt-8 mb-16"></v-divider>

    <AppInformation />

    <CheckStorageSupport />
  </div>
</template>

<script>
import CheckStorageSupport from '@/components/CheckStorageSupport.vue';
import AppInformation from '@/components/AppInformation.vue';

export default {
  name: 'standingOrderHomepage',
  created() {
    if (this.$store.state.safariBrowser) alert('Safari browser detected.\n\nThis app uses session cookies to function properly.')
    console.log(this.$store.state.paymentDetails);
  },
  components: {
    CheckStorageSupport,
    AppInformation
  },
  data() {
    return {
      imageName: 'undraw_standing_order.svg'
    }
  },
  computed: {
    svgWidth() {
      if (this.$vuetify.breakpoint.xs) return 250
      return '60%'
    }
  }
}
</script>

<style scoped>
.svg-image {
  opacity: 0.9;
}
</style>
